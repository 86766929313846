var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "tCombo",
      attrs: { title: _vm.editTitle, "mask-closable": false },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "Form",
        {
          ref: "tComboForm",
          attrs: {
            model: _vm.tComboForm,
            rules: _vm.tComboFormRule,
            "label-width": 100,
          },
        },
        [
          _c(
            "FormItem",
            { attrs: { label: "套餐名称", prop: "name" } },
            [
              _c("Input", {
                attrs: {
                  disabled: _vm.disabled,
                  type: "text",
                  maxlength: 50,
                  placeholder: "请输入套餐名称",
                },
                model: {
                  value: _vm.tComboForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.tComboForm, "name", $$v)
                  },
                  expression: "tComboForm.name",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: { type: "text" },
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          !_vm.disabled
            ? _c(
                "Button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.handelSubmit },
                },
                [_vm._v("提交")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }